import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import swal from "sweetalert2";
import {
  Grid,
  Modal,
  Button,
  Form,
  Label,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import DeleteTaskModal from "./DeleteTaskModal";
import TaskFileModal from "./TaskFileModal";

export default class InfoTaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarTask: {
        titulo: "",
        descripcion: "",
        fecha_creacion: null,
        allDay: false,
        fecha_inicio: null,
        hora_inicio: "",
        fecha_fin: null,
        hora_fin: "",
        perfil_usuario: null,
        usuario: null,
        tipo_tarea: null,
        tipo_mantenimiento_id: null,
        maquina_mantenimiento: null,
        status_tarea: null,
        status_color: "",
        status_tarea_id: 0,
        entregable: false,
        descripcion_entregable: "",
        tarea_file: [],
        tiene_periodicidad: false,
        periodicidad: null,
        codigo_iso_id: null,
        usuario_creador_id: null,
        involucrados: null,
        perfiles_involucrados: null,
      },
      openTask: false,
      usuariosPerfiles: [],
      periodo: "",
      usuario: "",
      perfil: "",
      tipo_tarea: "",
      tipo_mantenimiento: "",
      maquinaria: "",
      codigo: "",
      showMachine: false,
      ids: [],
    };
  }

  openFileModal = () => {
    this.setState({
      openFileModal: true,
    });
  };

  componentWillReceiveProps(nextProps, nextState) {
    if (this.state.calendarTask.id !== nextProps.data.id) {
      this.setState({
        calendarTask: {
          ...this.state.calendarTask,
          ...nextProps.data,
        },
      });
    }
  }

  setPeriodicidad(value) {
    let periodo = this.state.periodos.find((p) => p.value === value);
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        periodicidad_id: value,
      },
      periodo: periodo.text,
    });
  }

  fetchUserProfile = async (value) => {
    let perfil = this.props.perfiles.find((p) => p.value === value);
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        perfil_usuario: value,
        perfiles_involucrados: value,
      },
      perfil: perfil,
    });

    await Feathers.service("usuario_perfil")
      .find({
        query: {
          perfil_id: this.state.calendarTask.tipo_tarea === 13
          ? {
              $in: value,
            }
          : value,
          $select: ["usuario_id"],
        },
      })
      .then((res) => {
        let result = res.map((usuarioId) => {
          return usuarioId.usuario_id;
        });
        Feathers.service("usuario")
          .find({
            query: {
              id: {
                $in: result,
              },
            },
          })
          .then((res) => {
            this.setState({
              usuariosPerfiles: res.data.map((u) => {
                return {
                  value: u.id,
                  text: u.nombre + " " + u.apellido,
                };
              }),
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setUserProfile(value) {
    let usuario = this.state.usuariosPerfiles.find((u) => u.value === value);
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        usuario: value,
        usuario_id: value,
        involucrados: value
      },
      usuario: this.state.calendarTask.tipo_tarea !== 13 ? usuario.text + usuario.apellido : value,
    });
  }

  setTiposTarea = async (value) => {
    let tipo_tarea = this.props.tipos_tarea.find((u) => u.value === value);

    if (value !== 1) {
      await this.setState({
        calendarTask: {
          ...this.state.calendarTask,
          tipo_mantenimiento_id: null,
          maquina_mantenimiento: null,
        },
        tipo_mantenimiento: "",
        maquinaria: "",
      });
    }

    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        tipo_tarea: value,
      },
      tipo_tarea: tipo_tarea.text,
    });
  };

  setTiposMantenimiento = async (value) => {
    let tipos_mantenimiento = this.props.tipos_mantenimiento.find(
      (u) => u.value === value
    );
    if (value === 2) {
      await this.setState({
        calendarTask: {
          ...this.state.calendarTask,
          maquina_mantenimiento: null,
        },
        maquinaria: "",
      });
    }

    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        tipo_mantenimiento_id: value,
      },
      tipo_mantenimiento: tipos_mantenimiento.text,
    });
  };

  closeModal = () => {
    this.setState({
      openTask: false,
    });
  };

  // close both modals and call callback
  closeModalComplete = () => {
    this.props.callback();
    this.props.onClose();
    this.setState({
      openTask: false,
    });
  };

  setMaquinarias(value) {
    let maquinaria = this.props.maquinas.find((m) => m.value === value);
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        maquina_mantenimiento: value,
      },
      maquinaria: maquinaria.text,
    });
  }

  setCodigos = (value) => {
    let codigo = this.props.codigos.find((c) => c.value === value);
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        codigo_iso_id: value,
      },
      codigo: codigo.text,
    });
  };

  //Esta funcion busca todos los ids que coincidan con una clave
  //los ids que obtengan serán los que se actualizarán
  //Así podemos actualizar varias tareas a la vez, (en especial las que son repetidas)
  fetchIDs = async () => {
    await Feathers.service("calendario_tarea")
      .find({
        query: {
          clave: this.state.calendarTask.clave,
        },
      })
      .then((res) => {
        this.setState({
          ids: res.data.map((id) => {
            return id.id;
          }),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateData = () => {
    this.fetchIDs();
    if (
      !this.state.calendarTask.titulo ||
      !this.state.calendarTask.fecha_fin ||
      !this.state.calendarTask.fecha_inicio ||
      !this.state.calendarTask.descripcion ||
      !this.state.calendarTask.usuario ||
      !this.state.calendarTask.tipo_tarea
    ) {
      swal({
        title: "Algunos campos están vacíos.",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }
    swal({
      title: "¿Actualizar datos de esta tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, actualizar",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        const params = {
          query: {
            id: {
              $in: this.state.ids,
            },
          },
        };
        Feathers.service("calendario_tarea")
          .patch(
            null,
            {
              titulo: this.state.calendarTask.titulo,
              descripcion: this.state.calendarTask.descripcion,
              allDay: this.state.calendarTask.allDay,
              fecha_inicio: this.state.calendarTask.fecha_inicio,
              hora_inicio: this.state.calendarTask.hora_inicio,
              fecha_fin: this.state.calendarTask.fecha_fin,
              hora_fin: this.state.calendarTask.hora_fin,
              perfil_usuario_id: this.state.calendarTask.perfil_usuario,
              usuario_id: this.state.calendarTask.usuario,
              tipo_tarea_id: this.state.calendarTask.tipo_tarea,
              tipo_mantenimiento_id: this.state.calendarTask
                .tipo_mantenimiento_id,
              maquina_mantenimiento_id: this.state.calendarTask
                .maquina_mantenimiento,
              entregable: this.state.calendarTask.entregable,
              descripcion_entregable: this.state.calendarTask
                .descripcion_entregable,
              tiene_periodicidad: this.state.calendarTask.tiene_periodicidad,
              codigo_iso_id: this.state.calendarTask.codigo_iso_id,
            },
            params
          )
          .then((res) => {
            this.props.onClose();
            this.props.callback();
          })
          .catch((err) => {
            swal({
              title: "Se produjo un error. Intente de nuevo",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true,
            });
          });
      }
    });
  };

  selectPeriodicidad() {
    if (this.state.calendarTask.tiene_periodicidad) {
      return (
        <Form.Dropdown
          label="Frecuencia de la tarea "
          placeholder="Seleccione una opción"
          selection
          value={this.state.calendarTask.periodicidad}
          options={this.state.periodos}
          onChange={(e, { name, value }) => this.setPeriodicidad(value)}
        />
      );
    }
  }

  selectStartTime() {
    if (this.state.calendarTask.tiene_periodicidad) {
      return (
        <Form.Input
          disabled
          type="time"
          name="hora_inicio"
          value={this.state.calendarTask.hora_inicio}
          onChange={(e, { name, value }) =>
            this.setState({
              calendarTask: {
                ...this.state.calendarTask,
                [name]: value,
              },
            })
          }
        />
      );
    } else {
      return (
        <Form.Input
          disabled={this.state.calendarTask.allDay === true}
          type="time"
          name="hora_inicio"
          value={this.state.calendarTask.hora_inicio}
          onChange={(e, { name, value }) =>
            this.setState({
              calendarTask: {
                ...this.state.calendarTask,
                [name]: value,
              },
            })
          }
        />
      );
    }
  }

  selectEndTime() {
    if (this.state.calendarTask.tiene_periodicidad) {
      return (
        <Form.Input
          disabled
          type="time"
          name="hora_fin"
          value={this.state.calendarTask.hora_fin}
          onChange={(e, { name, value }) =>
            this.setState({
              calendarTask: {
                ...this.state.calendarTask,
                [name]: value,
              },
            })
          }
        />
      );
    } else {
      return (
        <Form.Input
          disabled={this.state.calendarTask.allDay === true}
          type="time"
          name="hora_fin"
          value={this.state.calendarTask.hora_fin}
          onChange={(e, { name, value }) =>
            this.setState({
              calendarTask: {
                ...this.state.calendarTask,
                [name]: value,
              },
            })
          }
        />
      );
    }
  }

  actionbuttons() {
    if (
      this.props.data.status_tarea_id === 3 &&
      this.props.data.entregable === true
    ) {
      return (
        <div>
          <Button
            type="submit"
            floated="right"
            color="red"
            labelPosition="left"
            icon="delete calendar"
            content="Eliminar tarea"
            basic
            onClick={() => {
              this.setState({
                openTask: true,
              });
            }}
          />
          <Button
            type="submit"
            floated="right"
            color="blue"
            labelPosition="left"
            icon="folder outline"
            content="Ver entregables"
            basic
            onClick={this.openFileModal}
          />
        </div>
      );
    } else if (
      this.props.data.status_tarea_id === 3 &&
      this.props.data.entregable === false
    ) {
      return (
        <Button
          type="submit"
          floated="right"
          color="red"
          labelPosition="left"
          icon="delete calendar"
          content="Eliminar tarea"
          basic
          onClick={() => {
            this.setState({
              openTask: true,
            });
          }}
        />
      );
    } else if (this.props.data.status_tarea_id === 4) {
      return <></>;
    } else {
      return (
        <div>
          <Button
            type="submit"
            floated="right"
            positive
            labelPosition="left"
            icon="send"
            content="Actualizar datos"
            onClick={this.updateData}
          />
          <Button
            type="submit"
            floated="right"
            color="red"
            labelPosition="left"
            icon="delete calendar"
            content="Eliminar tarea"
            basic
            onClick={() => {
              this.setState({
                openTask: true,
              });
            }}
          />
        </div>
      );
    }
  }

  isMaintenance = () => {
    if (this.state.calendarTask.tipo_tarea === 1) {
      return (
        <Form.Dropdown
          label="¿Qué tipo de mantenimiento desea hacer?"
          placeholder="Seleccione una opción"
          selection
          search
          value={this.state.calendarTask.tipo_mantenimiento_id}
          onChange={(e, { name, value }) => this.setTiposMantenimiento(value)}
          options={this.props.tipos_mantenimiento}
        />
      );
    }
  };

  isMaintenanceMachine() {
    if (
      this.state.calendarTask.tipo_mantenimiento_id === 1 &&
      this.state.calendarTask.tipo_tarea === 1
    ) {
      return (
        <Form.Dropdown
          label="¿A qué maquina se le hará mantenimiento? "
          placeholder="Seleccione una opción"
          selection
          search
          value={this.state.calendarTask.maquina_mantenimiento}
          onChange={(e, { name, value }) => this.setMaquinarias(value)}
          options={this.props.maquinas}
        />
      );
    }
  }

  isStandardISO = () => {
    const currentTask = this.state.calendarTask.tipo_tarea;
    let isoTask = this.props.tipos_tarea.find((c) => c.value === currentTask);
    if (isoTask !== undefined) {
      if (isoTask.text === "ISO 9000") {
        return (
          <Form.Dropdown
            label="Código de la norma "
            placeholder="Código de la norma"
            selection
            search
            value={this.state.calendarTask.codigo_iso_id}
            onChange={(e, { name, value }) => this.setCodigos(value)}
            options={this.props.codigos}
          />
        );
      }
    }
  };

  componentDidMount() {
    this.fetchUserProfile();
  }

  render() {
    return (
      <Modal open={this.props.open} size={"large"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column width={10}>
                <div>
                  <h2>Detalles</h2>
                  <section style={{ fontSize: "14px" }}>
                    <Label basic size={"medium"}>
                      <Icon name="add to calendar" />
                      {this.state.calendarTask.fecha_creacion}
                    </Label>
                    <Label
                      style={{
                        backgroundColor: this.state.calendarTask.status_color,
                        color: "white",
                      }}
                    >
                      {this.state.calendarTask.status_tarea}
                    </Label>
                    {this.state.calendarTask.periodicidad === 2 ? (
                      <Label color="teal" size={"medium"}>
                        <Icon name="repeat" />
                        Tarea semanal
                      </Label>
                    ) : (
                      <></>
                    )}

                    {this.state.calendarTask.periodicidad === 1 ? (
                      <Label color="blue" size={"medium"}>
                        <Icon name="repeat" />
                        Tarea diaria
                      </Label>
                    ) : (
                      <></>
                    )}

                    {this.state.calendarTask.periodicidad === 3 ? (
                      <Label color="green" size={"medium"}>
                        <Icon name="repeat" />
                        Tarea mensual
                      </Label>
                    ) : (
                      <></>
                    )}

                    {this.state.calendarTask.periodicidad === 4 ? (
                      <Label color="orange" size={"medium"}>
                        <Icon name="repeat" />
                        Tarea anual
                      </Label>
                    ) : (
                      <></>
                    )}
                  </section>
                </div>
              </Grid.Column>
              <Grid.Column width={6}>
                <Button
                  circular
                  size="mini"
                  basic
                  icon="close"
                  floated="right"
                  onClick={this.props.onClose}
                ></Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <Form size={"large"}>
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column>
                  <Form.Input
                    label="Titulo"
                    name="titulo"
                    value={this.state.calendarTask.titulo}
                    type="text"
                    required
                    onChange={(e, { name, value }) =>
                      this.setState({
                        calendarTask: {
                          ...this.state.calendarTask,
                          [name]: value,
                        },
                      })
                    }
                  />
                  <Form.Dropdown
                    required
                    label="Tipo de tarea"
                    value={this.state.calendarTask.tipo_tarea}
                    selection
                    options={this.props.tipos_tarea}
                    onChange={(e, { name, value }) => this.setTiposTarea(value)}
                  />

                  {this.isMaintenance()}
                  {this.isMaintenanceMachine()}
                  {this.isStandardISO()}

                  <Form.Dropdown
                    required
                    label="Perfil"
                    name="perfil"
                    placeholder="Seleccione una opción"
                    selection
                    search
                    multiple={this.state.calendarTask.tipo_tarea === 13}
                    value={this.state.calendarTask.tipo_tarea === 13 ? this.state.calendarTask.perfiles_involucrados : this.state.calendarTask.perfil_usuario }
                    onChange={(e, { name, value }) =>
                      this.fetchUserProfile(value)
                    }
                    options={this.props.perfiles}
                  />

                  <Form.Dropdown
                    required
                    label="Empleado asignado"
                    value={this.state.calendarTask.tipo_tarea === 13 ? this.state.calendarTask.involucrados : this.state.calendarTask.usuario }
                    multiple={this.state.calendarTask.tipo_tarea === 13}
                    selection
                    search
                    options={this.state.usuariosPerfiles}
                    onChange={(e, { name, value }) =>
                      this.setUserProfile(value)
                    }
                  />

                  <Form.TextArea
                    label="Descripción"
                    type="text"
                    name="descripcion"
                    required
                    value={this.state.calendarTask.descripcion}
                    onChange={(e, { name, value }) =>
                      this.setState({
                        calendarTask: {
                          ...this.state.calendarTask,
                          [name]: value,
                        },
                      })
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Grid
                    style={{
                      marginTop: "2px",
                      marginBottom: "2px",
                    }}
                  >
                    <section
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div>
                        {this.state.calendarTask.tiene_periodicidad ? (
                          <label htmlFor="" style={{ fontWeight: "bold" }}>
                            Fecha de inicio
                          </label>
                        ) : (
                          <label htmlFor="" style={{ fontWeight: "bold" }}>
                            Fecha y hora de inicio
                          </label>
                        )}
                      </div>
                      <Checkbox
                        disabled={
                          this.state.calendarTask.tiene_periodicidad === true
                        }
                        label="¿Todo el día?"
                        name="allDay"
                        checked={this.state.calendarTask.allDay}
                        onChange={(e, { name, checked }) => {
                          this.setState({
                            calendarTask: {
                              ...this.state.calendarTask,
                              [name]: checked,
                            },
                          });
                        }}
                      />
                    </section>
                    <Grid.Row columns="2">
                      <Grid.Column width="10">
                        <Form.Input
                          readOnly={
                            this.state.calendarTask.tiene_periodicidad === true
                          }
                          name="fecha_inicio"
                          type="date"
                          value={this.state.calendarTask.fecha_inicio}
                          onChange={(e, { name, value }) =>
                            this.setState({
                              calendarTask: {
                                ...this.state.calendarTask,
                                [name]: value,
                              },
                            })
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width="6">
                        {this.selectStartTime()}
                      </Grid.Column>
                    </Grid.Row>
                    {this.state.calendarTask.tiene_periodicidad ? (
                      <label htmlFor="" style={{ fontWeight: "bold" }}>
                        Fecha fin
                      </label>
                    ) : (
                      <label htmlFor="" style={{ fontWeight: "bold" }}>
                        Fecha y hora de fin
                      </label>
                    )}
                    <Grid.Row columns="2">
                      <Grid.Column width="10">
                        <Form.Input
                          readOnly={
                            this.state.calendarTask.tiene_periodicidad === true
                          }
                          name="fecha_fin"
                          type="date"
                          value={this.state.calendarTask.fecha_fin}
                          onChange={(e, { name, value }) =>
                            this.setState({
                              calendarTask: {
                                ...this.state.calendarTask,
                                [name]: value,
                              },
                            })
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width="6">
                        {this.selectEndTime()}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Form.Field>
                    <Checkbox
                      label="¿Solicitar entregable para esta tarea?"
                      name="entregable"
                      //value={this.state.calendarTask.entregable}
                      checked={this.state.calendarTask.entregable}
                      onChange={(e, { name, checked }) => {
                        if (checked) {
                          this.setState({
                            calendarTask: {
                              ...this.state.calendarTask,
                              [name]: checked,
                            },
                          });
                        } else {
                          this.setState({
                            calendarTask: {
                              ...this.state.calendarTask,
                              [name]: checked,
                              descripcion_entregable: "",
                            },
                          });
                        }
                      }}
                    />
                  </Form.Field>

                  {this.state.calendarTask.entregable ? (
                    <Form.TextArea
                      label="A continuación describa que requiere en el entregable"
                      type="text"
                      name="descripcion_entregable"
                      value={this.state.calendarTask.descripcion_entregable}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          calendarTask: {
                            ...this.state.calendarTask,
                            [name]: value,
                          },
                        })
                      }
                    />
                  ) : (
                    <p></p>
                  )}

                  {this.actionbuttons()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
        <DeleteTaskModal
          calendarTaskId={this.state.calendarTask.id}
          calendarTaskClave={this.state.calendarTask.clave}
          tienePeriodicidad={this.state.calendarTask.tiene_periodicidad}
          open={this.state.openTask}
          onClose={this.closeModal}
          onCloseComplete={this.closeModalComplete}
        />
        {this.props.data.entregable ? (
          <TaskFileModal
            open={this.state.openFileModal}
            onClose={this.closeFileModal}
            idTask={this.state.calendarTask.id}
          />
        ) : null}
      </Modal>
    );
  }
}
