import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Button,
  Grid,
  Icon,
  Progress,
  Header,
  Dropdown,
  Form,
  Select,
  Label,
  Input,
  Search,
} from "semantic-ui-react";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import Feathers from "../redux/FeathersRedux";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";
import "moment/locale/es";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { exportToXls } from "../utils/Utils";
import ReportFilter from "../components/FIlters/ReportFilter";
import { laravelService } from "../utils/laravelService";
import Config from "../config";

const renderEmployeeSearchResults = ({ id, nombre, apellido }) => (
  <Label key={id} content={nombre + " " + apellido} />
);

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

String.prototype.capitalizeWords = function() {
  let words = this.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(" ");
};

export default class FinishedProductReportContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tareas: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 25,
      modalOpened: false,
      editModalOpened: false,
      currentRow: {},
      filter: {
        initDay: "",
        lastDay: "",
        cliente_id: null,
        usuario_id: null,
        rodilloValue: "",
        partidaValue: "",
        pedidoValue: "",
        statusValue: "",
      },
      filters: [
        "rodillo",
        "pedido",
        "partida",
        "range",
        "status",
        "stage",
        "user",
      ],
      filterByPedido: null,
      filterByRodillo: null,
      filterByEtapa: null,
      filterByEtapaOn: null,
      filterByPartida: null,
      filterByStage: null,
      filterByStatus: null,
      stage: "",
      filterDates: {
        initDay: "",
        lastDay: "",
        rodilloValue: "",
        partidaValue: "",
        pedidoValue: "",
      },
      cliente: "",
      clienteResults: null,
      clienteLoading: null,
      status_options: [
        {
          key: 1,
          text: "Iniciada",
          value: 1,
        },
        {
          key: 2,
          text: "Finalizada",
          value: 2,
        },
        {
          key: 3,
          text: "Cancelada",
          value: 3,
        },
        {
          key: 4,
          text: "Pendiente",
          value: 4,
        },
      ],
      filterOptions: {
        employee_id: null,
      },
      filterStageOptions: [
        {
          key: 1,
          value: "revisión",
          text: "Revisión",
        },
        {
          key: 2,
          value: "molino",
          text: "Molino",
        },
        {
          key: 3,
          value: "desviste",
          text: "Desviste",
        },
        {
          key: 4,
          value: "construcción",
          text: "Construcción",
        },
        {
          key: 5,
          value: "vulcanizado",
          text: "Vulcanizado",
        },
        {
          key: 6,
          value: "desbaste",
          text: "Desbaste",
        },
        {
          key: 7,
          value: "rectificado",
          text: "Rectificado",
        },
        // {
        //   key: 8,
        //   value: "control de calidad",
        //   text: "Control de Calidad",
        // },
        {
          key: 9,
          value: "desmonte",
          text: "Desmonte",
        },
        {
          key: 10,
          value: "corte",
          text: "Corte",
        },
      ],
      $sort: {
        $fechaInicioSortDirection: null,
        $fechaFinSortDirection: null,
        $saeSortDirection: null,
      },

      // search bar objects

      employee: null,
      // search bar loadings

      employeeLoading: null,
      shouldExport: false,
      // search bar results

      employeeResults: null,
      columna: "pedido_id",
      sortDirection: false,
      showSae: false,
      tableColumns: [
        {
          Header: "OT",
          id: "ot",
          accessor: (d) => {
            if (d.id) return d.id;
            if (!d.id) return "---";
          },
        },
        {
          Header: "SAE",
          id: "ref_sae",
          accessor: (d) => {
            if (d.ref_sae) return d.ref_sae;
            if (!d.ref_sae) return "---";
          },
        },
        {
          Header: "Orden de Trabajo",
          id: "pedido_id",
          accessor: (d) => {
            if (d.pedido_id) return d.pedido_id;
            if (!d.pedido_id) return "---";
          },
        },
        {
          Header: "Rodillo",
          id: "rodilloid",
          accessor: (d) => {
            if (d.rodillo_id) return "#" + d.rodillo_id;
            if (!d.rodillo_id) return "---";
          },
        },
        {
          Header: "Partida",
          headerStyle: { fontSize: 16 },
          id: "partida_id",
          accessor: (d) => {
            if (d.partida_id) return d.partida_id;
            return "---";
          },
        },
        {
          Header: "Cliente",
          id: "clienteNombre",
          accessor: (d) => d.clienteNombre,
        },
        {
          Header: "Nombre de persona",
          id: "nombre",
          accessor: (d) => {
            if (d.nombre)
              return (d.nombre + " " + d.apellido).capitalizeWords();
            return "---";
          },
        },
        {
          Header: "Fecha Inicio",
          id: "hora_inicio",
          accessor: (d) => {
            if (d.hora_inicio) return d.hora_inicio;
            if (!d.hora_inicio) return;
          },
        },
        {
          Header: "Fecha Fin",
          id: "hora_fin",
          accessor: (d) => {
            if (d.hora_fin) return d.hora_fin;
            if (!d.hora_fin) return;
          },
        },

        {
          Header: "Comentario",
          id: "comentario",
          accessor: (d) => {
            if (d.comentario) return d.comentario;
            if (!d.comentario) return "---";
          },
        },
        {
          Header: "Etapa",
          id: "etapa",
          accessor: (d) => {
            if (d.etapa) return d.etapa;
            if (!d.etapa) return;
          },
        },
        {
          Header: "Estado",
          id: "status_tarea_id",
          accessor: (d) => {
            if (d.status_tarea_id == 2) {
              return (
                <Label tag color="green">
                  Terminada
                </Label>
              );
            }
            if (d.status_tarea_id == 1) {
              return (
                <Label tag color="blue">
                  Iniciada
                </Label>
              );
            }
            if (d.status_tarea_id == 3) {
              return (
                <Label tag color="grey">
                  Cancelada
                </Label>
              );
            }
            if (!d.status_tarea_id) {
              return (
                <Label tag color="red">
                  Pendiente
                </Label>
              );
            }
          },
        },
      ],
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
    this.handleEmployeeResultSelect = this.handleEmployeeResultSelect.bind(
      this
    );
    this.handleEmployeeSearchChange = this.handleEmployeeSearchChange.bind(
      this
    );
  }

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "SAE":
        array.data.sort((a, b) => {
          return a.ref_sae - b.ref_sae;
        });
        break;
      case "Nombre de persona":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase();
          const nameB = b.nombre.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Cliente":
        array.data.sort((a, b) => {
          const nameA = a.cliente.toUpperCase();
          const nameB = b.cliente.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "#Unidad":
        array.data.sort((a, b) => {
          let A = a.rodillo_id ? a.rodillo_id : 0;
          let B = b.rodillo_id ? b.rodillo_id : 0;
          return a.rodillo_id - b.rodillo_id;
        });
        break;
      case "Fecha Inicio":
        array.data.sort((a, b) => {
          const nameA = a.hora_inicio ? a.hora_inicio : "";
          const nameB = b.hora_inicio ? b.hora_inicio : "";
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha Inicio":
        array.data.sort((a, b) => {
          const nameA = a.hora_fin ? a.hora_fin : "";
          const nameB = b.hora_fin ? b.hora_fin : "";
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Estado":
        array.data.sort((a, b) => {
          return a.status_tarea_id - b.status_tarea_id;
        });
        break;
      case "Etapa":
        array.data.sort((a, b) => {
          return a.etapa - b.etapa;
        });
        break;
      case "Comentario":
        array.data.sort((a, b) => {
          const nameA = a.comentario ? a.comentario : "";
          const nameB = b.comentario ? b.comentario : "";
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  handleEmployeeResultSelect = async (e, { result }) => {
    this.setState({ employee: result.nombre + " " + result.apellido });
    console.log("res emp", result);
    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        employee_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleEmployeeSearchChange = (e, { value }) => {
    this.setState({ employeeLoading: true, employee: value });

    setTimeout(() => {
      if (this.state.employee.length < 1) return this.resetEmployeeComponent();

      Feathers.service("usuario")
        .find({
          query: {
            $or: [
              {
                nombre: { $like: `%${value}%` },
              },
              {
                apellido: { $like: `%${value}%` },
              },
            ],
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            employeeLoading: false,
            employeeResults: res.data,
          });
        });
    }, 500);
  };

  resetEmployeeComponent = () =>
    this.setState({
      employeeLoading: false,
      employeeResults: [],
      employee: "",
    });

  fetchData(state = {}) {
    this.setState({ loading: true }, async () => {
      let query = {};

      if (this.state.pageSize) {
        query["page_size"] = this.state.pageSize;
      }

      if (this.state.columna && this.state.sortDirection !== null) {
        query["sort_column"] = this.state.columna;
        query["sort_direction"] = this.state.sortDirection ? "ASC" : "DESC";
      }

      if (this.state.filter.rodilloValue) {
        query["rodillo_id"] = this.state.filter.rodilloValue;
      }

      if (this.state.filter.pedidoValue) {
        query["pedido_id"] = this.state.filter.pedidoValue;
      }

      if (this.state.filter.partidaValue) {
        query["partida_id"] = this.state.filter.partidaValue;
      }

      if (this.state.filter.statusValue) {
        query["status_tarea_id"] = this.state.filter.statusValue;
      }

      if (this.state.filter.cliente_id) {
        query["cliente_id"] = this.state.filter.cliente_id;
      }

      if (this.state.filter.status_tarea_id) {
        query["status_tarea_id"] = this.state.filter.status_tarea_id;
      }

      if (this.state.filterByStage) {
        query["etapa"] = this.state.filterByStage;
      }

      if (this.state.filter.initDay) {
        query["startDate"] = moment(this.state.filter.initDay)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm");
      }

      if (this.state.filter.initDay && this.state.filter.lastDay) {
        query["endDate"] = moment(this.state.filter.lastDay)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm");
      }
      console.log(this.state.filterOptions.employee_id);
      if (this.state.filterOptions.employee_id) {
        query["usuario_id"] = this.state.filterOptions.employee_id;
      }
      console.log("query", query);
      await laravelService
        .get(`reports/tasks?page=${this.state.page + 1}`, {
          params: query,
        })
        .then((res) => {
          console.log("res", res.data.data);

          this.setState({
            tareas: res.data.data,
            total: res.data.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 10,
          });
        });
    });
  }

  handleFilterSubmit = async () => {
    await this.fetchData(this.state);
  };

  handleCleanFilter = async () => {
    this.resetEmployeeComponent();
    this.resetClienteComponent();

    await this.setState(
      {
        filterByPedido: null,
        filterByRodillo: null,
        filterByEtapaOn: null,
        filterByEtapa: null,
        filterByStatus: null,
        statusValue: null,
        filterByStage: null,
        cliente: "",
        filterDates: {
          initDay: "",
          lastDay: "",
        },
        filterOptions: {
          employee_id: null,
        },
        filter: {
          initDay: "",
          lastDay: "",
          cliente_id: null,
          rodilloValue: "",
          partidaValue: "",
          pedidoValue: "",
          statusValue: "",
        },
      },
      async () => {
        await this.fetchData(this.state);
      }
    );
  };

  onRodilloChange = (rodillo) => {
    this.setState({
      filter: {
        ...this.state.filter,
        rodilloValue: rodillo,
      },
    });
  };

  onPartidaChange = (partida) => {
    this.setState({
      filter: {
        ...this.state.filter,
        partidaValue: partida,
      },
    });
  };

  onPedidoChange = (pedido) => {
    this.setState({
      filter: {
        ...this.state.filter,
        pedidoValue: pedido,
      },
    });
  };

  changeInitDay = (date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        initDay: date,
      },
    });
  };

  changeLastDay = (date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        lastDay: date,
      },
    });
  };

  onStatusChange = (status) => {
    console.log("status", status);
    this.setState({
      filter: {
        ...this.state.filter,
        statusValue: status,
      },
    });
  };

  onStageChange = (stage) => {
    this.setState(
      {
        filterByStage: stage,
      },
      () => console.log("state stage", this.state)
    );
  };

  handleClientResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    await this.setState(
      {
        filter: {
          ...this.state.filter,
          cliente_id: result.id,
        },
      },
      () => console.log("state stage", this.state)
    );

    this.fetchData(this.state);
  };

  handleClientSearchChange = async (e, { value }) => {
    console.log(value, "--");
    await this.setState({ clienteLoading: true, cliente: value });
    setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then(
          (res) => {
            this.setState({
              clienteLoading: false,
              clienteResults: res.data,
            });
          },
          () => {
            console.log("state", this.state);
          }
        );
    }, 500);
  };

  resetClienteComponent = () =>
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });

  handleEmployeeResultSelect = async (e, { result }) => {
    this.setState({ employee: result.nombre + " " + result.apellido });
    console.log("res emp", result);
    await this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          employee_id: result.id,
        },
      },
      () => {
        this.fetchData(this.state);
      }
    );
  };

  handleEmployeeSearchChange = (e, { value }) => {
    this.setState({ employeeLoading: true, employee: value });

    setTimeout(() => {
      if (this.state.employee.length < 1) return this.resetEmployeeComponent();

      Feathers.service("usuario")
        .find({
          query: {
            $or: [
              {
                nombre: { $like: `%${value}%` },
              },
              {
                apellido: { $like: `%${value}%` },
              },
            ],
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            employeeLoading: false,
            employeeResults: res.data,
          });
        });
    }, 500);
  };

  resetEmployeeComponent = () =>
    this.setState({
      employeeLoading: false,
      employeeResults: [],
      employee: "",
    });

  renderFilterOptions = () => {
    return (
      <Form size="small">
        <Form.Group>
          <ReportFilter
            loading={this.state.loading}
            filters={this.state.filters}
            initDay={this.state.filter.initDay}
            lastDay={this.state.filter.lastDay}
            changeInitDay={this.changeInitDay}
            changeLastDay={this.changeLastDay}
            rodilloValue={this.state.filter.rodilloValue || ""}
            onRodilloChange={this.onRodilloChange}
            partidaValue={this.state.filter.partidaValue || ""}
            onChangePartida={this.onPartidaChange}
            pedidoValue={this.state.filter.pedidoValue || ""}
            onPedidoChange={this.onPedidoChange}
            statusFilterOptions={this.state.status_options}
            statusValue={this.state.filter.statusValue || ""}
            onStatusChange={this.onStatusChange}
            costumer={this.state.filter.cliente}
            costumers={this.state.clienteResults}
            onSelectCostumer={this.handleClientResultSelect}
            onSearchCostumerChange={this.handleClientSearchChange}
            costumerResultRenderer={renderClientSearchResults}
            stages={this.state.filterStageOptions}
            onStageChange={this.onStageChange}
            stage={this.state.filterByStage}
            employeeLoading={this.state.employeeLoading}
            employee={this.state.employee}
            employeeResults={this.state.employeeResults}
            handleEmployeeResultSelect={this.handleEmployeeResultSelect}
            handleEmployeeSearchChange={this.handleEmployeeSearchChange}
            renderEmployeeSearchResults={renderEmployeeSearchResults}
          />
          <Form.Field>
            <label>Filtrar</label>
            <Button
              basic
              circular
              icon="filter"
              onClick={this.handleFilterSubmit}
            />
          </Form.Field>
          <Form.Field>
            <label>Limpiar</label>
            <Button
              basic
              circular
              color="red"
              icon="delete"
              onClick={this.handleCleanFilter}
            />
          </Form.Field>
          <Form.Field>
            <label>Exportar</label>
            <Button
              basic
              color="green"
              circular
              icon="file excel outline"
              onClick={() => {
                let urlExcel = `${Config.laravelUrl}/api/excel/taskReport?pedido_id=${this.state.filter.pedidoValue || ""}
                &rodillo_id=${this.state.filter.rodilloValue || ""}&partida_id=${this.state.filter.partidaValue || ""}
                &usuario_id=${this.state.filterOptions.employee_id || ""}&status_tarea_id=${this.state.filter.status_tarea_id || ""}
                &etapa=${this.state.filterByStage || ""}`;

                if (this.state.filter.initDay && this.state.filter.lastDay) {
                  urlExcel += `startDate=${moment(
                    this.state.filter.initDay
                  )
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm")}&`;
                  urlExcel += `endDate=${moment(
                    this.state.filter.lastDay
                  )
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm")}&`;
                }

                console.log("url", urlExcel)

                window.open("", "_blank").location.href = urlExcel;
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="checkmark" content="Reporte de Tareas" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <this.renderFilterOptions />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ReactTable
                columns={
                  this.state.showSae
                    ? this.state.tableColumns
                    : this.state.tableColumns.slice(1)
                }
                manual
                data={this.state.tareas}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                page={this.state.page}
                pageIndex={this.state.page}
                pageSize={this.state.pageSize}
                onFetchData={this.fetchData}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (newSorted[0].id == this.state.columna) {
                    direction = !this.state.sortDirection;
                  } else {
                    console.log(this.state.sortDirection);
                    direction = !this.state.sortDirection;
                  }

                  this.setState(
                    {
                      columna: newSorted[0].id,
                      sortDirection: this.state.sortDirection ? false : true,
                    },
                    () => {
                      this.fetchData();
                    }
                  );
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
