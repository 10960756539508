import React, { Component } from "react";
import { Modal, Label, Icon, Button } from "semantic-ui-react";
import { getStageName } from "../../utils/Utils";
import Config from "../../config";

export default class PartidaModal extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    console.log(this.props.data);
    return (
      <Modal open={this.props.isOpened} size={"small"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          ORDEN DE TRABAJO: {this.props.data.pedido_id} / PARTIDA:{" "}
          {this.props.data.id}
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          />
        </Modal.Header>
        <Modal.Content>
          <div>
            {this.props.data.rodillo ? (
              this.props.data.rodillo.length ? (
                this.props.data.rodillo.map((r) => (
                  <div
                    key={r.id}
                    style={{ textAlign: "center", marginBottom: "10px" }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                      {" "}
                      RODILLO: {r.id} -{" "}
                    </span>
                    <Label basic color="blue">
                      {getStageName(r.stage)}
                    </Label>
                    <Button
                    disabled={r.stage <= 12}
                    size="mini"
                    color="green"
                    icon="tag"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      window.location.href = `${Config.laravelUrl}/api/createLabelQA/${r.id}`
                    }}
                  >
                    Etiqueta QA
                  </Button>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "10px",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {" "}
                    RODILLO: {this.props.data.rodillo.id} -{" "}
                  </span>
                  <Label basic color="blue">
                    {getStageName(this.props.data.rodillo.stage)}
                  </Label>
                  <Button
                    disabled={this.props.data.rodillo.stage <= 12}
                    size="mini"
                    color="green"
                    icon="tag"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      window.location.href = `${Config.laravelUrl}/api/createLabelQA/${this.props.data.rodillo.id}`
                    }}
                  >
                    Etiqueta QA
                  </Button>
                </div>
              )
            ) : null}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
