import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import { Grid, Modal, Button, Form, Checkbox } from "semantic-ui-react";
import moment from "moment";
import swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import { nanoid } from "nanoid";
import {connect} from 'react-redux';

class CreateTaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarTask: {
        titulo: "",
        descripcion: "",
        fecha_creacion: moment().format("YYYY-MM-DD"),
        fecha_inicio: "",
        hora_inicio: "",
        fecha_fin: "",
        hora_fin: "",
        perfil_usuario_id: null,
        usuario_id: null,
        tipo_tarea_id: null,
        tipo_mantenimiento_id: null,
        maquina_mantenimiento_id: null,
        codigo_iso_id: null,
        status_tarea_id: null,
        entregable: false,
        descripcion_entregable: "",
        allDay: false,
        tiene_periodicidad: false,
        periodicidad_id: null,
      },

      usuariosPerfiles: [],
      tipo_mantenimiento: "",
      codigo: "",
      periodo: "",
      usuario: "",
      perfil: "",
      tipo_tarea: "",
      maquinaria: "",
      loading: false,
    };
  }

  //De acuerdo al perfil seleccionado mostramos los usuarios ligados a ese perfil
  fetchUserProfile = async (value) => {
    let perfil = this.props.perfiles.find((p) => p.value === value);
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        perfil_usuario_id: value,
      },
      perfil: perfil,
    });
    console.log(value, "array");
    await Feathers.service("usuario_perfil")
      .find({
        query: {
          // where in query is only used in meeting tasks
          perfil_id:
            this.state.calendarTask.tipo_tarea_id === 13
              ? {
                  $in: value,
                }
              : value,
          $select: ["usuario_id"],
        },
      })
      .then((res) => {
        let result = res.map((usuarioId) => {
          return usuarioId.usuario_id;
        });
        Feathers.service("usuario")
          .find({
            query: {
              id: {
                $in: result,
              },
            },
          })
          .then((res) => {
            this.setState({
              usuariosPerfiles: res.data.map((u) => {
                return {
                  value: u.id,
                  text: u.nombre + " " + u.apellido,
                };
              }),
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setTiposTarea = async (value) => {
    let tipo_tarea = this.props.tipos_tarea.find((u) => u.value === value);

    if (value !== 1) {
      await this.setState({
        calendarTask: {
          ...this.state.calendarTask,
          tipo_mantenimiento_id: null,
          maquina_mantenimiento: null,
        },
        tipo_mantenimiento: "",
        maquinaria: "",
      });
    }

    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        tipo_tarea_id: value,
      },
      tipo_tarea: tipo_tarea.text,
    });
  };

  setPeriodicidad(value) {
    let periodo = this.props.periodos.find((p) => p.value === value);
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        periodicidad_id: value,
      },
      periodo: periodo.text,
    });
  }

  setUserProfile(value) {
    let usuario = this.state.usuariosPerfiles.find((u) => u.value === value);
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        usuario_id: value,
      },
      usuario: usuario ? usuario.text + usuario.apellido : "",
    });
  }

  setTiposMantenimiento(value) {
    let tipos_mantenimiento = this.props.tipos_mantenimiento.find(
      (u) => u.value === value
    );
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        tipo_mantenimiento_id: value,
      },
      tipo_mantenimiento: tipos_mantenimiento.text,
    });
  }

  setMaquinarias(value) {
    let maquinaria = this.props.maquinas.find((m) => m.value === value);
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        maquina_mantenimiento_id: value,
      },
      maquinaria: maquinaria.text,
    });
  }

  setCodigos(value) {
    let codigo = this.props.codigos.find((c) => c.value === value);
    this.setState({
      calendarTask: {
        ...this.state.calendarTask,
        codigo_iso_id: value,
      },
      codigo: codigo.text,
    });
  }

  createNewTask = () => {
    console.log(
      this.state.calendarTask.usuario_id,
      this.state.calendarTask.perfil_usuario_id
    );
    const clave = nanoid(10);
    if (
      this.state.calendarTask.tiene_periodicidad &&
      !this.state.calendarTask.periodicidad_id
    ) {
      swal({
        title: "Falta registrar la frecuencia de la tarea.",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }
    if (
      !this.state.calendarTask.titulo ||
      !this.state.calendarTask.fecha_fin ||
      !this.state.calendarTask.fecha_inicio ||
      !this.state.calendarTask.descripcion ||
      !this.state.calendarTask.usuario_id ||
      !this.state.calendarTask.tipo_tarea_id ||
      !this.state.calendarTask.hora_inicio ||
      !this.state.calendarTask.hora_fin
    ) {
      swal({
        title: "Faltan datos por registrar.",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }
    swal({
      title: "¿Agregar tarea al calendario?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, agregar",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ loading: true }, () => {
          Feathers.service("calendario_tarea")
            .create({
              clave: clave,
              titulo: this.state.calendarTask.titulo,
              descripcion: this.state.calendarTask.descripcion,
              fecha_creacion: moment().format("YYYY-MM-DD"),
              allDay: this.state.calendarTask.allDay,
              fecha_inicio: this.state.calendarTask.fecha_inicio,
              hora_inicio: this.state.calendarTask.hora_inicio,
              fecha_fin: this.state.calendarTask.fecha_fin,
              hora_fin: this.state.calendarTask.hora_fin,
              perfil_usuario_id:
                this.state.calendarTask.tipo_tarea_id !== 13
                  ? this.state.calendarTask.perfil_usuario_id
                  : null,
              usuario_id:
                this.state.calendarTask.tipo_tarea_id !== 13
                  ? this.state.calendarTask.usuario_id
                  : null,
              tipo_tarea_id: this.state.calendarTask.tipo_tarea_id,
              maquina_mantenimiento_id: this.state.calendarTask
                .maquina_mantenimiento_id,
              tipo_mantenimiento_id: this.state.calendarTask
                .tipo_mantenimiento_id,
              tiene_periodicidad: this.state.calendarTask.tiene_periodicidad,
              periodicidad_id: this.state.calendarTask.periodicidad_id,
              status_tarea_id: 1,
              entregable: this.state.calendarTask.entregable,
              descripcion_entregable: this.state.calendarTask
                .descripcion_entregable,
              codigo_iso_id: this.state.calendarTask.codigo_iso_id,
              usuario_creador_id: this.props.user.id, // current user id
              involucrados:
                this.state.calendarTask.tipo_tarea_id === 13
                  ? JSON.stringify(this.state.calendarTask.usuario_id)
                  : null,
              perfiles_involucrados:
                this.state.calendarTask.tipo_tarea_id === 13
                  ? JSON.stringify(this.state.calendarTask.perfil_usuario_id)
                  : null,
            })
            .then((result) => {
              this.setState({
                loading: false,
                calendarTask: {
                  titulo: "",
                  descripcion: "",
                  fecha_creacion: moment().format("YYYY-MM-DD"),
                  fecha_inicio: "",
                  hora_inicio: "",
                  fecha_fin: "",
                  hora_fin: "",
                  perfil_usuario_id: null,
                  usuario_id: null,
                  tipo_tarea_id: null,
                  tipo_mantenimiento_id: null,
                  status_tarea_id: null,
                  entregable: false,
                  descripcion_entregable: "",
                  allDay: false,
                  tiene_periodicidad: false,
                  periodicidad_id: null,
                  maquina_mantenimiento_id: null,
                  codigo_iso_id: null,
                },
              });
              this.props.callback();
              this.props.onClose();
            })
            .catch((err) => {
              console.log(err);
              swal({
                title: "Se produjo un error. Intente de nuevo",
                type: "error",
                confirmButtonText: "ok",
                reverseButtons: true,
              });
              this.setState({ loading: false });
            });
        });
      }
    });
  };

  selectPeriodicidad() {
    if (this.state.calendarTask.tiene_periodicidad) {
      return (
        <Form.Dropdown
          label="Frecuencia de la tarea "
          placeholder="Seleccione una opción"
          selection
          options={this.props.periodos}
          onChange={(e, { name, value }) => this.setPeriodicidad(value)}
        />
      );
    }
  }

  selectStartTime() {
    if (this.state.calendarTask.tiene_periodicidad) {
      return (
        <Form.Input
          disabled
          type="time"
          name="hora_inicio"
          value={this.state.calendarTask.hora_inicio}
          onChange={(e, { name, value }) =>
            this.setState({
              calendarTask: {
                ...this.state.calendarTask,
                [name]: value,
              },
            })
          }
        />
      );
    } else {
      return (
        <Form.Input
          disabled={this.state.calendarTask.allDay === true}
          type="time"
          name="hora_inicio"
          value={this.state.calendarTask.hora_inicio}
          onChange={(e, { name, value }) =>
            this.setState({
              calendarTask: {
                ...this.state.calendarTask,
                [name]: value,
              },
            })
          }
        />
      );
    }
  }

  selectEndTime() {
    if (this.state.calendarTask.tiene_periodicidad) {
      return (
        <Form.Input
          disabled
          type="time"
          name="hora_fin"
          value={this.state.calendarTask.hora_fin}
          onChange={(e, { name, value }) =>
            this.setState({
              calendarTask: {
                ...this.state.calendarTask,
                [name]: value,
              },
            })
          }
        />
      );
    } else {
      return (
        <Form.Input
          disabled={this.state.calendarTask.allDay === true}
          type="time"
          name="hora_fin"
          value={this.state.calendarTask.hora_fin}
          onChange={(e, { name, value }) =>
            this.setState({
              calendarTask: {
                ...this.state.calendarTask,
                [name]: value,
              },
            })
          }
        />
      );
    }
  }

  render() {
    return (
      <Modal open={this.props.open} size={"large"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          Nueva Tarea
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>

        <Modal.Content>
          <Form onSubmit={this.createNewTask} size={"large"}>
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column>
                  <Form.Input
                    label="Titulo"
                    type="text"
                    name="titulo"
                    value={this.state.calendarTask.titulo}
                    onChange={(e, { name, value }) =>
                      this.setState({
                        calendarTask: {
                          ...this.state.calendarTask,
                          [name]: value,
                        },
                      })
                    }
                  />

                  <Form.Dropdown
                    label="Tipo de tarea "
                    placeholder="Seleccione una opción"
                    selection
                    search
                    onChange={(e, { name, value }) => this.setTiposTarea(value)}
                    options={this.props.tipos_tarea}
                  />

                  {this.state.calendarTask.tipo_tarea_id === 1 ? (
                    <Form.Dropdown
                      label="¿Qué tipo de mantenimiento desea hacer? "
                      placeholder="Seleccione una opción"
                      selection
                      search
                      onChange={(e, { name, value }) =>
                        this.setTiposMantenimiento(value)
                      }
                      options={this.props.tipos_mantenimiento}
                    />
                  ) : (
                    <p></p>
                  )}

                  {this.state.calendarTask.tipo_mantenimiento_id === 1 ? (
                    <Form.Dropdown
                      label="¿A qué maquina se le hará mantenimiento? "
                      placeholder="Seleccione una opción"
                      selection
                      search
                      onChange={(e, { name, value }) =>
                        this.setMaquinarias(value)
                      }
                      options={this.props.maquinas}
                    />
                  ) : (
                    <p></p>
                  )}

                  {this.state.tipo_tarea === "ISO 9000" ? (
                    <Form.Dropdown
                      label="Código de la norma"
                      placeholder="Seleccione una opción"
                      selection
                      search
                      onChange={(e, { name, value }) => this.setCodigos(value)}
                      options={this.props.codigos}
                    />
                  ) : (
                    <p></p>
                  )}

                  <Form.Dropdown
                    label="Perfil"
                    name="perfil"
                    placeholder="Seleccione una opción"
                    selection
                    multiple={this.state.calendarTask.tipo_tarea_id === 13}
                    search
                    value={this.state.calendarTask.perfil_usuario_id}
                    onChange={(e, { name, value }) =>
                      this.fetchUserProfile(value)
                    }
                    options={this.props.perfiles}
                  />

                  <Form.Dropdown
                    label="Empleado"
                    name="usuario"
                    placeholder="Seleccione una opción"
                    selection
                    multiple={this.state.calendarTask.tipo_tarea_id === 13}
                    search
                    value={this.state.calendarTask.usuario_id}
                    onChange={(e, { name, value }) =>
                      this.setUserProfile(value)
                    }
                    options={this.state.usuariosPerfiles}
                  />

                  <Form.TextArea
                    label="Descripción de la tarea"
                    type="text"
                    name="descripcion"
                    value={this.state.calendarTask.descripcion}
                    onChange={(e, { name, value }) =>
                      this.setState({
                        calendarTask: {
                          ...this.state.calendarTask,
                          [name]: value,
                        },
                      })
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <Checkbox
                      style={{ fontSize: "16px" }}
                      label="¿Repetir tarea?"
                      name="tiene_periodicidad"
                      checked={this.state.calendarTask.tiene_periodicidad}
                      onChange={(e, { name, checked }) => {
                        if (checked) {
                          this.setState({
                            calendarTask: {
                              ...this.state.calendarTask,
                              [name]: checked,
                              hora_inicio: "00:00",
                              hora_fin: "23:59",
                            },
                          });
                        } else {
                          this.setState({
                            calendarTask: {
                              ...this.state.calendarTask,
                              [name]: checked,
                              hora_inicio: "",
                              hora_fin: "",
                              periodicidad_id: null,
                            },
                          });
                        }
                      }}
                    />
                  </Form.Field>
                  {this.selectPeriodicidad()}
                  <Grid style={{ marginTop: "2px", marginBottom: "2px" }}>
                    <section
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div>
                        {this.state.calendarTask.tiene_periodicidad ? (
                          <label htmlFor="" style={{ fontWeight: "bold" }}>
                            Fecha de inicio
                          </label>
                        ) : (
                          <label htmlFor="" style={{ fontWeight: "bold" }}>
                            Fecha y hora de inicio
                          </label>
                        )}
                      </div>
                      <Checkbox
                        disabled={
                          this.state.calendarTask.tiene_periodicidad === true
                        }
                        label="¿Todo el día?"
                        name="allDay"
                        checked={this.state.calendarTask.allDay}
                        onChange={(e, { name, checked }) => {
                          if (checked) {
                            this.setState({
                              calendarTask: {
                                ...this.state.calendarTask,
                                [name]: checked,
                                hora_inicio: "00:00",
                                hora_fin: "23:59",
                              },
                            });
                          } else {
                            this.setState({
                              calendarTask: {
                                ...this.state.calendarTask,
                                [name]: checked,
                                hora_inicio: "",
                                hora_fin: "",
                              },
                            });
                          }
                        }}
                      />
                    </section>
                    <Grid.Row columns="2">
                      <Grid.Column width="10">
                        <Form.Input
                          name="fecha_inicio"
                          type="date"
                          value={this.state.calendarTask.fecha_inicio}
                          onChange={(e, { name, value }) =>
                            this.setState({
                              calendarTask: {
                                ...this.state.calendarTask,
                                [name]: value,
                              },
                            })
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width="6">
                        {this.selectStartTime()}
                      </Grid.Column>
                    </Grid.Row>
                    {this.state.calendarTask.tiene_periodicidad ? (
                      <label htmlFor="" style={{ fontWeight: "bold" }}>
                        Fecha fin
                      </label>
                    ) : (
                      <label htmlFor="" style={{ fontWeight: "bold" }}>
                        Fecha y hora de fin
                      </label>
                    )}
                    <Grid.Row columns="2">
                      <Grid.Column width="10">
                        <Form.Input
                          name="fecha_fin"
                          type="date"
                          value={this.state.calendarTask.fecha_fin}
                          onChange={(e, { name, value }) =>
                            this.setState({
                              calendarTask: {
                                ...this.state.calendarTask,
                                [name]: value,
                              },
                            })
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width="6">
                        {this.selectEndTime()}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Form.Field>
                    <Checkbox
                      label="¿Solicitar entregable para esta tarea?"
                      name="entregable"
                      //value={this.state.calendarTask.entregable}
                      checked={this.state.calendarTask.entregable}
                      onChange={(e, { name, checked }) =>
                        this.setState({
                          calendarTask: {
                            ...this.state.calendarTask,
                            [name]: checked,
                          },
                        })
                      }
                    />
                  </Form.Field>

                  {this.state.calendarTask.entregable ? (
                    <Form.TextArea
                      label="A continuación describa que requiere en el entregable"
                      type="text"
                      name="descripcion_entregable"
                      value={this.state.calendarTask.descripcion_entregable}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          calendarTask: {
                            ...this.state.calendarTask,
                            [name]: value,
                          },
                        })
                      }
                    />
                  ) : (
                    <p></p>
                  )}

                  <Button
                    type="submit"
                    floated="right"
                    positive
                    labelPosition="left"
                    icon="send"
                    content="Registrar tarea"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps)(CreateTaskModal)